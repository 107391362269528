import React, { Suspense } from "react";
import { useSelector } from "react-redux";

const TransEnglish = React.lazy(() => import("./languagedata/en"));
const TransHungarian = React.lazy(() => import("./languagedata/hu"));

const Translation = (props) => {
  const language = useSelector((state) => state.tempLanguage);

  console.log(language);

  return (
    <React.Fragment>
      {language === "en" ? (
        <Suspense fallback={<div style={{ height: "0px" }} />}>
          <TransEnglish />
        </Suspense>
      ) : null}
      {language === "hu" ? (
        <Suspense fallback={<div style={{ height: "0px" }} />}>
          <TransHungarian />
        </Suspense>
      ) : null}
    </React.Fragment>
  );
};

export default Translation;
