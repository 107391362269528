//nocheck
import React, { useState } from "react";
import { List } from "react-feather";
import { Button } from "reactstrap";
import styled from "styled-components";
import HelpCenterInside from "./HelpCenterInside";
import HelpText from "./HelpText";

const TocDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow-y: auto;
  transition: all 0.15s;
  opacity: 0;
  padding: 20px;
  padding-top: 30px;
  padding-left: 40px;
  &&.visib {
    opacity: 1;
    z-index: 600;
  }
`;

const HelpDiv = styled.div`
  position: absolute;
  padding: 20px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow-y: auto;
  transition: all 0.15s;
  opacity: 0;
  overscroll-behavior: none;
  &&.visib {
    opacity: 1;
    z-index: 600;
  }
`;

const ButtonDiv = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 1000;
  transition: all 0.15s;
  opacity: 0;
  &&.visib {
    opacity: 1;
  }
`;

const HelpCenterBlog = (props) => {
  const [showToc, setShowToc] = useState(true);
  const [showHelp, setShowHelp] = useState(false);

  const goThere = (where) => {
    setShowToc(false);

    document.getElementById("myhelpdiv").scrollTop =
      document.getElementById(where)?.offsetTop;
    setTimeout(() => setShowHelp(true), 150);
  };

  const goTOC = () => {
    setShowHelp(false);
    setTimeout(() => setShowToc(true), 150);
  };

  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
        }}
      >
        <TocDiv className={showToc ? "visib" : ""}>
          <HelpCenterInside goThere={goThere} />
        </TocDiv>
        <HelpDiv id="myhelpdiv" className={showHelp ? "visib" : ""}>
          <HelpText />
        </HelpDiv>
        <ButtonDiv className={showHelp ? "visib" : ""}>
          <Button
            className="bg-primary btn-icon rounded-circle"
            onClick={goTOC}
          >
            <List />
          </Button>
        </ButtonDiv>
      </div>
    </React.Fragment>
  );
};

export default HelpCenterBlog;
