// @ts-ignore

import thunk from 'redux-thunk';

// import logger from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducers/_index';

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export default store;
