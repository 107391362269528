//nocheck
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LazyImg = (props) => {
  return (
    <React.Fragment>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            paddingTop: (props.height / props.width) * 80 + "%",
            marginTop: props.top || "25px",
          }}
          className="kriklass"
        >
          <LazyLoadImage
            alt={"helpguide"}
            height={"100%"}
            className="kripic"
            src={props.img} // use normal <img> attributes as props
            width={"100%"}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LazyImg;
