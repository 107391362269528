import React from "react";
import ReactHtmlParser from "html-react-parser";
import store from "redux/store";

export const encodedStr = (rawStr) => {
  return rawStr
    .replace(/&/g, "&amp;")
    .replace(/>/g, "gt;")
    .replace(/</g, "lt;");
};

export const decodedStr = (rawStr) => {
  return rawStr
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&nbsp;/g, " ");
};

export const tx = (uuid, orig = "", avar = "", bvar = "") => {
  if (avar === true) {
    return orig;
  }

  orig = store.getState().appTransParts[uuid || "bibibi"] || orig;
  if (avar === "nbsp") {
    orig = orig.replace(/ /g, String.fromCharCode(160));
  } else {
    orig = orig.replace(/xxx/g, avar);
    orig = orig.replace(/yyy/g, bvar);
  }
  return decodedStr(orig);
};

export const ty = (uuid, orig = "", avar = "", bvar = "") => {
  orig = store.getState().appTransParts[uuid || "bibibi"] || orig;
  orig = orig.replace(/xxx/g, avar);
  if (orig.includes("[[[")) {
    orig = orig.replace(/\[\[\[(.*?)\]\]\]/, "<a href=" + bvar + ">$1</a>");
  }

  return prepareFormat(decodedStr(orig));
};

export const tz = (uuid, orig = "", avar = "", bvar = "") => {
  orig = store.getState().appTransParts[uuid || "bibibi"] || orig;
  // orig = orig.replace(/xxx/g, avar);
  orig = decodedStr(orig);
  if (orig.includes("[[[")) {
    const tempOrig = orig.split(/\[\[\[|\]\]\]/);
    orig = (
      <React.Fragment>
        {tempOrig[0]}
        <span
          id={bvar || ""}
          style={{ color: "#2185d0", cursor: "pointer", fontWeight: "bold" }}
          onClick={avar}
        >
          {tempOrig[1].replace("]]]", "")}
        </span>
        {tempOrig[2]}
      </React.Fragment>
    );
  }

  return orig;
};

export const prepareFormat = (html) => {
  html = html.replace(/\*(.*?)\*/g, "<span class='bold'>$1</span>");
  html = html.replace(/_(.*?)_/g, "<span class='italic'>$1</span>");
  html = html.replace(/\{(.*?)\}/g, "<span class='colored'>$1</span>");
  return ReactHtmlParser(html);
};

export const prepareFormatTerms = (html) => {
  html = html.replace(/\*(.*?)\*/g, "<span class='termbold'>$1</span>");
  html = html.replace(/_(.*?)_/g, "<span class='italic'>$1</span>");
  html = html.replace(/\{(.*?)\}/g, "<span class='colored'>$1</span>");
  return ReactHtmlParser(html);
};
