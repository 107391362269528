import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const SearchService = (props) => {
  const location = useLocation();

  useEffect(() => {
    console.log(location.search);
    const search = location.search;

    const invitee = search
      .match(/invitee=[^&]*/)?.[0]
      .replace("invitee=", "")
      .split("&")[0];

    if (invitee) {
      localStorage.setItem("inv", invitee);
    }
  }, [location]);

  return <React.Fragment></React.Fragment>;
};

export default SearchService;
