import { createSlice } from '@reduxjs/toolkit';

const tempLanguageSlice = createSlice({
  initialState: 'en',
  name: 'tempLanguage',
  reducers: {
    setTempLanguage: (state, action) => {
      return action.payload;
    },
  },
});

export const { setTempLanguage } = tempLanguageSlice.actions;

export default tempLanguageSlice.reducer;
