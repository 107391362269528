//nocheck

import { tx } from "@translations/languagedata/globalize";
import React from "react";
import LazyImg from "./LazyImg";
// import styled from "styled-components";

const HelpText = (props) => {
  return (
    <React.Fragment>
      <h3 id="kswhatis" className="subtit">
        {tx("niko0", "What is IniShare?")}
      </h3>
      <p className="lineit">
        {tx(
          "niko1",
          "IniShare is a professional knowledge sharing application.",
        )}{" "}
        {tx(
          "niko2",
          "With it you can easily create digital booklets and books out of all kinds of different files with very little technical knowledge.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko3",
          "Your books can contain videos, pictures, pdf files, PowerPoint presentations, sound files etc.",
        )}{" "}
        {tx(
          "niko4",
          "These appear in the app as integral parts of your book or booklet.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx("niko5", "It works virtually on any reasonably modern device:")}{" "}
        {tx(
          "niko6",
          "In web browsers on notebooks and desktop computers and as an app on phones and tablets, ",
        )}{" "}
      </p>
      <h3 id="kswhy" className="subtit">
        {tx("niko7", "What problem does it solve?")}
      </h3>
      <p className="lineit">
        {tx(
          "niko8",
          "It's easy to share files or send links and attachments today.",
        )}{" "}
        {tx("niko9", "This is called Information Sharing.")}{" "}
      </p>
      <p className="lineit">
        {tx("niko10", "However what you really want to share, is KNOWLEDGE.")}{" "}
        {tx("niko11", "And knowledge today are in lots of forms.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko12",
          "You can have a pdf or two telling something about your product or your ideas.",
        )}{" "}
        {tx(
          "niko13",
          "But often there is a video where you show HOW something works, or a simple PowerPoint presentation explaining something important about your things.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko14",
          "Normally you would send these to your clients or friends as separate files or links, leaving it up to them to put together the whole concept if they can.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko15",
          "The Idea behind a KNOWLEDGE sharing application is that you don't have to compromise.",
        )}{" "}
        {tx(
          "niko16",
          "You can send over evertyhing compiled the way you want to and have a much better chance of reaching understanding.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko17",
          "That such a book or booklet looks much more professional than a bunch of attachments or weird links is an added bonus.",
        )}{" "}
      </p>
      <h3 id="kshow" className="subtit">
        {tx("niko18", "How do you share these books with others?")}
      </h3>
      <p className="lineit">
        {tx(
          "niko19",
          "The beauty of IniShare is that you have full control over who has access to your books and only the people you give access to are able to read them.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko20",
          "As the simplest way, you can just type in the e-mail addresses of a few readers and they instantly get access to your book.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko21",
          "Or you can create links which expire after a certain number of uses or a certain length of time, and send it to people who, when they click on it, can get access to your book.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko22",
          "Or you can just show a QR code to attendees of a meeting and they can get instant access to a book.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko23",
          "Or you can just sell your books on your website and notify IniShare about each sale and the buyer will get access to IniShare.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko24",
          "Or you can sell with IniShare itself setting up your sales page in less than a minute.",
        )}{" "}
      </p>
      <h3 className="subtit" id="ksuse">
        {tx("niko25", "What are the use cases?")}
      </h3>
      <p className="lineit">
        {tx(
          "niko26",
          "On the lower end you can easily compile your product briefings or company materials into IniShare booklets and promote yourself like that.",
        )}{" "}
        {tx(
          "niko27",
          "You can even create business proposal booklets including the most important data or files regarding your products or services.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko28",
          "Going higher you can use such booklets for on-boarding and company briefings.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko29",
          "You can hold complete seminars or webinars with IniShare with the added bonus that you can share your materials with your attendees through a simple link or QR-code, where only they get access to the materials.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko30",
          "Even further you can create complete online addendums to your books and courses including the type of information you can't include in a book or ebook today (multimedia and even interactive exercises.)",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko31",
          "And let's not forget that you can compile a book out of your best e-mails or written thoughts, spice it up with some videos and publish it actually selling the result to your clients in a much more capable application than an ebook or a printed book, not mentioning that it's simpler, too.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko32",
          "And you could write your next textbook in IniShare if you want to.",
        )}{" "}
      </p>
      <h3 className="subtit" id="ksinst">
        {tx("niko33", "Installation")}
      </h3>
      <p className="lineit">
        {tx(
          "niko34",
          "You can use IniShare in a simple webbrowser or you can download IniShare for Android and iOS devices.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko35",
          "In a webbrowser just go to https://app.inishare.com. On phones and tablets go to the Google Play store or the App Store and search for IniShare.",
        )}{" "}
      </p>
      <h3 className="subtit" id="ksfree">
        {tx("niko36", "Free version vs. Pro version")}{" "}
      </h3>
      <p className="lineit">
        {tx(
          "niko37",
          "Reaading books somebody else shared with you in IniShare is always free.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko38",
          "You can also create books with the free version but you can only upload 50MB worth of files and you can share each book with maximum five people.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko39",
          "In the paid version where you have a monthly subscription you have no such limit.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko40",
          "You can buy a subscription within the app clicking on the Subscribe button on the My Account page.",
        )}{" "}
      </p>
      <LazyImg
        img={"/help/en/a1.png"}
        height={291}
        width={251}
        max={50}
        top={"15px"}
      />
      <LazyImg img={"/help/en/a2.png"} height={138} width={338} max={50} />

      <h3 className="subtit" id="kshelp">
        {tx("niko43", "Getting Help")}
      </h3>
      <p className="lineit">
        {tx(
          "niko44",
          "This page contains answers to almost every questions you might have.",
        )}{" "}
        {tx(
          "niko45",
          "Specifically at the end we have a Troubleshooting part where most common problems and the solution to them are listed in an ever expanding list.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "niko46",
          "If you have other questions or feedback you can write to support@inishare.com.",
        )}{" "}
        {tx(
          "niko47",
          "But for a faster resolution it is useful to check out the Troubleshooting section.",
        )}{" "}
      </p>

      <h3 className="subtit" id="kslibrary">
        {tx("bweniko0", "The Library")}
      </h3>
      <p className="lineit">
        {tx("bweniko1", "This is your library showing all your books.")}{" "}
      </p>
      <LazyImg img={"/help/en/a3.png"} height={409} width={343} />
      <p className="lineit">
        {tx("bweniko2", "There are two types of books.")}{" "}
        {tx("bweniko3", "Those you created, and those others shared with you.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko4",
          "To select which books you want to see click on the filter icon in the top left corner.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a4.png"} height={142} width={339} />
      <p className="lineit">
        {tx(
          "bweniko5",
          "In the menu you can choose between Books by Me (books created by you), Books by Others (books others shared with you) and Sample Books (a few example books).",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a5.png"} height={127} width={337} />
      <h3 className="subtit" id="ksbooksett">
        {tx("bweniko6", "Book Settings")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko7",
          "In the top right corner of each book you created there's a little cog.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a6.png"} height={111} width={342} />
      <p className="lineit">
        {tx(
          "bweniko8",
          "If you click on it you can change the title, the info and the cover of the book, and you can also share the book with others.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko9",
          "In the top right corner of each book others shared with you, there is a little info icon.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a7.png"} height={150} width={340} />
      <p className="lineit">
        {tx("bweniko10", "Click on it to see the main info about the book. ")}{" "}
      </p>
      <LazyImg img={"/help/en/a8.png"} height={365} width={340} />
      <p className="lineit">
        {tx(
          "bweniko11",
          "You can also hide the book from your list here, and remove it from your book list by using the buttons at the bottom.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a9.png"} height={211} width={368} />
      <h3 className="subtit" id="ksview">
        {tx("bweniko12", "Viewing Books shared by others")}
      </h3>
      <p className="lineit">
        {tx("bweniko13", "To open a book just click on it in the Library.")}{" "}
        {tx(
          "bweniko14",
          "Some books are only made up of a list of parts, others can have chapters and each chapter has a list of parts.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko15",
          "If there are chapters, you will see the individual chapters you have in that book.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a10.png"} height={412} width={416} />
      <p className="lineit">
        {tx(
          "bweniko16",
          "If you click on a Chapter (or the book doesn't have chapters) the chapter opens and you will see the parts in that chapter or the book.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a11.png"} height={556} width={413} />
      <p className="lineit">
        {tx(
          "bweniko17",
          "If there is only one part in a chapter or in the book, it automatically opens.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko18",
          "If there are more than one part, you have to click on the part you want to open.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko19",
          "There can be various parts (including interactive exercises and multimedia) and it's rather obvious what you can do with them.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko20",
          "You can exit this part by clicking on the back arrow button in the header.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a14.png"} height={306} width={410} />
      <p className="lineit">
        {tx(
          "bweniko21",
          "To get back to the chapters if there are chapters, click on the back arrow in the top left corner.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a12.png"} height={201} width={412} />
      <p className="lineit">
        {tx(
          "bweniko22",
          "Then to go back to the Library click on the back arrow again.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a13.png"} height={206} width={413} />
      <h3 className="subtit" id="ksviewyours">
        {tx("bweniko23", "Viewing books created by you")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko24",
          "To open a book you created make sure you select the Books By Me option from the filter menu in the Library.",
        )}{" "}
        {tx("bweniko24a", "Then open the book by clicking on it.")}
      </p>
      <LazyImg img={"/help/en/a15.png"} height={189} width={412} />
      <p className="lineit">
        {tx(
          "bweniko25",
          "You will see the individual chapters or parts you have in that book.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a17.png"} height={463} width={412} />
      <p className="lineit">
        {tx("bweniko26", "At the top you will see a Presentation switch.")}{" "}
        {tx(
          "bweniko27",
          "If you want to see this book as a reader would see it, turn on the switch.",
        )}{" "}
        {tx(
          "bweniko28",
          "(If you turn it off you will view the book in editing mode, and everything opens for editing.)",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a18.png"} height={131} width={412} />
      <p className="lineit">
        {tx(
          "bweniko29",
          "If there are chapters and you click on a chapter the chapter opens and you will see the items in that chapter.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a20.png"} height={489} width={408} />
      <p className="lineit">
        {tx("bweniko30", "Click on the item you want to open.")}{" "}
      </p>
      <p className="lineit">
        {tx("bweniko31", "It opens in the Editor which is expalined later.")}{" "}
      </p>

      <p className="lineit">
        {tx("bweniko32", "To close the open Editor click on the close button.")}{" "}
      </p>
      <LazyImg img={"/help/en/a21.png"} height={283} width={409} />
      <h3 className="subtit" id="kscreate">
        {tx("bweniko33", "Creating a New Book")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko34",
          "You can create new Books in the Library by switching to the Books By Me list and pressing the +Create New Book button at the bottom of the list of books.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a22.png"} height={169} width={414} />
      <p className="lineit">{tx("bweniko35", "A dialog opens.")} </p>
      <LazyImg img={"/help/en/a23.png"} height={503} width={408} />
      <p className="lineit">
        {tx("bweniko36", "Here you can provide some info about your new book.")}{" "}
        {tx(
          "bweniko37",
          "Title and subtitle (this is optional), author (by default it is you), info (useful for knowing what it is later and in case you want to share the book).",
        )}{" "}
        {tx("bweniko38", "Only the title and the author fields are mandatory.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko36acd",
          "You can also select whether you want to have chapters in your book or if the book should be a list of parts.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a25.png"} height={146} width={393} />
      <h3 className="subtit" id="kscover">
        {tx("bweniko39", "The Cover Designer")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko40",
          "You can also create a cover for the book which appears next to it in the library.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a26.png"} height={343} width={405} />
      <p className="lineit">
        {tx(
          "bweniko41",
          "You will see that the cover contains the title and the author.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko42",
          "If you click on the Edit cover link, you will be able to choose a different cover picture.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a28.png"} height={233} width={346} />
      <p className="lineit">
        {tx("bweniko42ac", "The Cover Editor dialog opens.")}{" "}
      </p>
      <LazyImg img={"/help/en/a29.png"} height={659} width={412} />
      <p className="lineit">
        {tx(
          "bweniko43",
          "By clicking on the Select background link you can select a picture form the gallery appearing.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko44",
          "Or you can create your own picture and click on the Use own picture link to select it as a file.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko45",
          "Or you can drag and drop a picture on the book in the dialog.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko46",
          "Then you can change the font type and color of the title and the author.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a30.png"} height={236} width={401} />
      <p className="lineit">
        {tx(
          "bweniko47",
          "Any you can change the color and intensity of the background behind the title and the color.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko48",
          "You can also turn them off entirely and just insert a completely new cover picture created in another program.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko49",
          "When you are done, press Save and the new cover will be saved and the dialog closes.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko50",
          "Press the Create button to create the new book and you will see it appearing in your Books By Me list.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a31.png"} height={301} width={405} />
      <h3 className="subtit" id="ksaddchapters">
        {tx("bweniko51", "Adding Chapters")}
      </h3>
      <p className="lineit">{tx("bweniko52", "If your book has chapters:")} </p>
      <p className="lineit">
        {tx(
          "bweniko53",
          "To add content to your book, click on the book to open it.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a35.png"} height={222} width={410} />
      <p className="lineit">
        {tx("bweniko54", "The list of Chapters opens.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko55",
          "Make sure that the Presentation switch is off, otherwise you won't be able to edit your book.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a36.png"} height={191} width={412} />
      <p className="lineit">
        {tx(
          "bweniko56",
          "To add the first Chapter to your book, press the Create new chapter button and a dialog appears.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a37.png"} height={201} width={410} />
      <p className="lineit">
        {tx(
          "bweniko57",
          "You can now type in a chapter title and optionally a subtitle.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a38.png"} height={296} width={411} />
      <p className="lineit">
        {tx("bweniko58", "Press Add to create the chapter.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko59",
          "To add more chapters, or delete a chapter or change its title, click on the three dots next to the chapter you want to change.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a40.png"} height={137} width={411} />
      <p className="lineit">
        {tx("bweniko60", "A few buttons appear around your chapter.")}{" "}
      </p>
      <LazyImg img={"/help/en/a41.png"} height={106} width={413} />
      <p className="lineit">
        {tx(
          "bweniko61",
          "To add a new chapter click on the New Chapter button above or below depending on where you want your new chapter.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko62",
          "To add a new section title click on the New Section button above or below, and you can set up a section title in the table of contents.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx("bweniko63", "To delete a chapter, click on the Delete button.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko64",
          "Note: You have to delete the contents of a chapter before you can delete it.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko65",
          "To change the chapter title or subtitle click on the Change button and edit the chapter data.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko66",
          "If you have more than one chapter you can change the sequence of the chapters by dragging and dropping the chapters and the section titles.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko67",
          "To edit the contents of a chapter, click on the chapter title to enter the contents.",
        )}{" "}
      </p>

      <h3 className="subtit" id="ksaddparts">
        {tx("bweniko68", "Adding parts to your chapter or book")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko69",
          "Each chapter (if you have chapters) or each book (if you don't) can contain multiple parts.",
        )}{" "}
        {tx("bweniko70", "A part consists of one or more files or text.")}{" "}
      </p>
      <LazyImg img={"/help/en/a43.png"} height={244} width={412} />
      <p className="lineit">
        {tx(
          "bweniko71",
          "To add a part at the bottom of your chapter or book click on the Create new part button.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a45.png"} height={188} width={415} />
      <p className="lineit">
        {tx(
          "bweniko72",
          "A dialog appears where you can set a title and a subtitle for your item.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a46.png"} height={286} width={409} />
      <p className="lineit">
        {tx(
          "bweniko73",
          "Press Add or OK when you are done setting these data and the part opens in the Editor.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko74",
          "To add parts in the middle of the list, or to delete an item, click on the three dots next to the item title.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a50.png"} height={158} width={412} />
      <p className="lineit">{tx("bweniko75", "A few buttons appear:")} </p>
      <LazyImg img={"/help/en/a51.png"} height={126} width={413} />
      <p className="lineit">
        {tx(
          "bweniko76",
          "To add a new part click on the New Part button above or below depending on where you want your new part.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko77",
          "To add a new section title click on the New Section button above or below, and you can set up a section title in the list of parts.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx("bweniko78", "To delete a part, click on the Delete button.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko79",
          "If you have more than one parts you can change the sequence of the Parts by dragging and dropping the parts and the section titles.",
        )}{" "}
      </p>
      <h3 className="subtit" id="kseditor">
        {tx("bweniko80", "The Editor")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko81",
          "To edit a part, you have to click on the part title to open the Editor.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a52.png"} height={129} width={412} />
      <p className="lineit">
        {tx(
          "bweniko82",
          "The Editor is a specialized word processor where you can do your magic and actually include all kinds of information in various formats.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a53.png"} height={732} width={412} />
      <p className="lineit">
        {tx(
          "bweniko83",
          "The Editor does work like a text editor, you can select text and move up and down, but you will have to get used to it at first.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko84",
          "If you want to add text, just type anything or copy and paste something into the editor.",
        )}{" "}
      </p>
      <h3 className="subtit" id="ksparagraph">
        {tx("bweniko85", "Paragraph Formatting")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko86",
          "To format paragraphs, click into the paragraph you want to format or select more than one with your mouse or with the keyboard arrows holding down the Shift key.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a54.png"} height={302} width={413} />
      <p className="lineit">
        {tx("bweniko87", "Then click on the paragraph sign in the toolbar.")}{" "}
      </p>
      <LazyImg img={"/help/en/a56.png"} height={154} width={407} />
      <p className="lineit">
        {tx(
          "bweniko88",
          "Now select one from the available paragraph formats.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a57.png"} height={275} width={339} />
      <p className="lineit">
        {tx(
          "bweniko89",
          "You can additionally format each element and paragraph individually or create styles to apply to all similar elements or paragraphs.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko90",
          "To do so make sure that you select only elements of the same type (i.e. only images or only paragraphs) and then press the little cog in the toolbar.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a60.png"} height={198} width={411} />
      <p className="lineit">
        {tx(
          "bweniko91",
          "A dialog appears which shows you how the selected paragraphs or elements are formatted.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a61.png"} height={716} width={407} />
      <p className="lineit">
        {tx(
          "bweniko92",
          "At the top you can select an existing style you want to work with.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko93",
          "With parapgraphs you can change the font, the font size, the color and the alignment and also the width of the paragraph and the spacing around it.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx("bweniko94", "With other modules you can change less.")}{" "}
      </p>
      <p className="lineit">
        {tx("bweniko95", "Change around what you want.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko96",
          "Now if you only want to apply this format to the currently selected parapgrahs or elements as an exception, press the Update this button at the bottom.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a63.png"} height={268} width={411} />
      <p className="lineit">
        {tx(
          "bweniko97",
          "If you want to permanently update the currently selected style to this new format, click on the Update all button.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a70.png"} height={258} width={406} />
      <p className="lineit">
        {tx(
          "bweniko98",
          "This changes the format of the currently selected style everywhere, and also assigns the selected style to the currently selected paragraphs or modules.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko99",
          "Important: If you press Update all, you change the formatting of that style in your whole book not just the current book.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko100",
          "If you want to create a new Style, click on the New button next to the list of styles at the top and provide a name for the new style.",
        )}{" "}
        {tx("bweniko101", "It will be created.")}{" "}
      </p>
      <LazyImg img={"/help/en/a71.png"} height={204} width={410} />
      <p className="lineit">
        {tx(
          "bweniko102",
          "Important: Creating a new style doesn't assign that style, so you still have to click Update all if you want to assign the style.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko103",
          "If you change some styles compared to an original style or if a paragraph has some styles with modifications you applied with Update this, then you will see a Revert to Default option.",
        )}{" "}
        {tx(
          "bweniko104",
          "If you press that then the individual adjustments will be removed and you will see the currently selected style as it is originally.",
        )}{" "}
        {tx(
          "bweniko105",
          "(You still have to press Update all to apply it to the current paragraphs or modules.)",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a72.png"} height={203} width={405} />
      <h3 className="subtit" id="ksbold">
        {tx("bweniko106", "Bold and Italic")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko107",
          "Bold and Italic formatting are somewhat unique in IniShare.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko108",
          "To add italic write a star where the italic part starts and write another star where it ends.",
        )}{" "}
        {tx("bweniko109", "(Eg. Joe is *very* nice.)")}{" "}
      </p>
      <LazyImg img={"/help/en/a74.png"} height={58} width={407} />
      <p className="lineit">
        {tx(
          "bweniko110",
          "When you step out of the paragraph this part will appear italic.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a76.png"} height={36} width={392} />
      <p className="lineit">
        {tx(
          "bweniko111",
          "To add bold, write two stars before the bold part and two stars after.",
        )}{" "}
        {tx("bweniko112", "(Eg. Joe is **very** nice.)")}{" "}
      </p>
      <LazyImg img={"/help/en/a77.png"} height={46} width={395} />
      <p className="lineit">
        {tx(
          "bweniko113",
          "When you leave the paragraph you are editing it will appear in bold.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a78.png"} height={39} width={388} />
      <h3 className="subtit" id="kslists">
        {tx("bweniko114", "Lists")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko115",
          "To create lists, select the paragraph(s) where you want a list, and click on the list button at the top",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a79.png"} height={220} width={410} />
      <p className="lineit">
        {tx("bweniko116", "Now select the type of list you want.")}{" "}
      </p>
      <LazyImg img={"/help/en/a80.png"} height={310} width={204} />
      <p className="lineit">{tx("bweniko117", "The result:")} </p>
      <LazyImg img={"/help/en/a81.png"} height={203} width={409} />
      <p className="lineit">
        {tx(
          "bweniko118",
          "You can experiment with this function and see how lists get extended when you press Enter after typing in a list item.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko119",
          "To turn off the list, press Enter in an empty list paragraph or click on the list button and select No list.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko120",
          "You can also indent your paragraphs or lists by pressing the Tab button to increase the indentation and pressing Shift+Tab to decrease indentation.",
        )}{" "}
      </p>
      <h3 className="subtit" id="kslinks">
        {tx("bweniko121", "Adding links")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko122",
          "Links can be added by clicking to the position where you want to insert the link and pressing the Link button in the toolbar:",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a83.png"} height={309} width={410} />
      <p className="lineit">
        {tx(
          "bweniko123",
          "Now fill in the link address and the text to appear as the link.",
        )}{" "}
        {tx(
          "bweniko124",
          "(If you leave the text empty the link will appear as it's shown under the link address.)",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a84.png"} height={289} width={412} />
      <p className="lineit">
        {tx(
          "bweniko125",
          "You will see that it appears in a coded form in the text.",
        )}{" "}
        {tx(
          "bweniko126",
          "Actually you can create a link manually as well following this notation:",
        )}{" "}
      </p>
      <p className="lineit">
        {tx("bweniko127", "[link text](https://link_address)")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko128",
          "If you click into the text of such a link, you can edit it by clicking on the Link button in the toolbar.",
        )}{" "}
      </p>
      <h3 className="subtit" id="ksspecial">
        {tx("bweniko129", "Special Elements")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko130",
          "To insert multimedia and interactive elements into your part, go to the location where you want to insert them, and click on the Add button in the toolbar or press F1.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a90.png"} height={336} width={411} />
      <p className="lineit">
        {tx("bweniko131", "You can then select from a list of options.")}{" "}
      </p>
      <LazyImg img={"/help/en/a91.png"} height={615} width={410} />
      <p className="lineit">
        {tx("bweniko132", "Click on the type of element you want to insert.")}{" "}
        {tx("bweniko133", "We show you an image element as an example.")}{" "}
      </p>
      <LazyImg img={"/help/en/a92.png"} height={314} width={492} />
      <p className="lineit">
        {tx(
          "bweniko134",
          "An image element looks like this before you set it up properly with the image itself.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a93.png"} height={227} width={407} />
      <p className="lineit">
        {tx(
          "bweniko135",
          "You can read on the element the instructions to actually fill it up with an image.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko136",
          "For example we can drag and drop an image here to set up this one.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a94.png"} height={459} width={410} />
      <p className="lineit">
        {tx(
          "bweniko137",
          "To delete such an element you can click on it and in the lower right corner you will see a little trash can icon to do so.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko138",
          "Most element types are relatively self-explanatory but there are a few common things.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko139",
          "You can move between text and these modules with the arrow keys, and by pressing the Backspace or Delete button when they are selected you can delete them.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko140",
          "If you press Enter when such a module is selected, you will insert a paragraph above the module.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko141",
          "If you are at the bottom with one of these modules selected, you can press the down arrow and a new paragraph will appear at the bottom.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko142",
          "Some modules have a little cog on the right when they are selected.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a97.png"} height={269} width={406} />
      <p className="lineit">
        {tx(
          "bweniko143",
          "Clicking on them opens a dialog where you can enter the necessary data to operate that module.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a98.png"} height={270} width={414} />
      <p className="lineit">
        {tx(
          "bweniko144",
          "For example in this case the address of the YouTube video.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a99.png"} height={261} width={413} />
      <LazyImg img={"/help/en/a100.png"} height={263} width={408} />
      <p className="lineit">
        {tx(
          "bweniko145",
          "In such cases you can click on the ? mark in the dialog to see the specific information needed, if you are not sure.",
        )}{" "}
      </p>
      <h3 className="subtit" id="ksvoice">
        {tx("bweniko146", "Inserting Voice Recording")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko147",
          "With IniShare you can record voice and insert the recording straight into your part.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko148",
          "Just press the little microphone button at the bottom.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a101.png"} height={143} width={413} />
      <p className="lineit">
        {tx(
          "bweniko149",
          "When you do so for the first time it will ask for permission to use the microphone.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko150",
          "When you click again, the voice recording starts through your microphone.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko151",
          "You will see that you can continue to take notes or write while the recording is happening.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a102.png"} height={145} width={413} />
      <p className="lineit">
        {tx(
          "bweniko152",
          "Then click on the STOP button to finish the recording.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko153",
          "The recording will now be saved to our servers and you will see two buttons.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a103.png"} height={116} width={412} />
      <p className="lineit">
        {tx("bweniko154", "With one you can cancel and drop the recording.")}{" "}
        {tx(
          "bweniko155",
          "The other will insert the recording at the current cursor position.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko156",
          "If you click on a voice recording, a little player appears at the top, and you can play the recording while you read the part.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a104.png"} height={82} width={395} />
      <LazyImg img={"/help/en/a106.png"} height={173} width={409} />
      <p className="lineit">
        {tx(
          "bweniko157",
          "You can also slow down or speed up the playing of the recording by clicking on the little number at the left of the player.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko158",
          "Also, if you select a voice recording in the Editor you will see a delete button appearing (which you can use to delete the recording from the part).",
        )}{" "}
      </p>
      <h3 className="subtit" id="ksphoto">
        {tx("bweniko159", "Inserting Photos")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko160",
          "You can also take photos and insert it into your parts.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a108.png"} height={125} width={414} />
      <p className="lineit">
        {tx(
          "bweniko161",
          "Just click on the camera at the bottom and take your photo.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko162",
          "The photo gets uploaded to the server and it will get inserted into your part.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko163",
          "If you click on the photo you will see a buttons in the lower right corner.",
        )}{" "}
      </p>

      <p className="lineit">
        {tx("bweniko164", "You can use it to delete the picture.")}{" "}
      </p>
      <h3 className="subtit" id="ksredo">
        {tx("bweniko165", "Undo and redo")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko166",
          "While you have a section open and you are editing, you can undo and redo your actions with the buttons in the top right corner.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a111.png"} height={185} width={412} />

      <h3 className="subtit" id="kspublish">
        {tx("bweniko204", "Publishing books")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko205",
          "Publishing a book means that you are making the current version available for people you want to share it with.",
        )}{" "}
        {tx(
          "bweniko206",
          "To do so, just open the settings menu from the Library clicking on the little cog in the top right corner of your book.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a120.png"} height={189} width={412} />
      <p className="lineit">
        {tx("bweniko207", "You will see the Info tab selected at the top.")}{" "}
      </p>
      <LazyImg img={"/help/en/a121.png"} height={219} width={401} />
      <p className="lineit">
        {tx(
          "bweniko208",
          "In the dialog you will see a part where it says Published Versions.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a122.png"} height={54} width={399} />
      <p className="lineit">
        {tx(
          "bweniko209",
          "If the book has not yet been published, it will say Not publfished yet.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko210",
          "If the book has already been published it will say the date of the last time it was published.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko211",
          "To publish it for the first time or to update the published version simply press the Publish link.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a123.png"} height={54} width={402} />
      <p className="lineit">
        {tx("bweniko212", "Now the book is ready to be shared with others.")}{" "}
      </p>
      <LazyImg img={"/help/en/a124.png"} height={60} width={396} />

      <h3 className="subtit" id="kssharesingle">
        {tx("bweniko213", "Sharing with Single Users")}
      </h3>
      <p className="lineit">
        {tx("bweniko214", "There are several ways to share books with others.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko215",
          "To do so, just open the book settings menu from the Library, clicking on the little cog in the top right corner of your book.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a120.png"} height={189} width={412} />
      <p className="lineit">
        {tx(
          "bweniko216",
          "The easiest sharing option is assigning the book to individual users.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko217",
          "To do so select the Users tab at the top of the Book Settings dialog.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a126.png"} height={200} width={410} />
      <p className="lineit">
        {tx(
          "bweniko218",
          "You will see the list of users this book is shared with.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a127.png"} height={415} width={402} />
      <p className="lineit">
        {tx(
          "bweniko219",
          "To add a user simply type in the e-mail addresses of the users (divided by a semicolon if you have more than one) and press the Add users button.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko220",
          "The users will now be added to the list of users with access to the book.",
        )}{" "}
        {tx(
          "bweniko221",
          "The book will appear in their Books By Others list instantly, or when they first sign up with the given e-mail address.",
        )}{" "}
      </p>
      <h3 className="subtit" id="kssharegroup">
        {tx("bweniko222", "Sharing with Groups")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko223",
          "You can also create groups from users you often share data with.",
        )}{" "}
        {tx("bweniko224", "For example your team at your workplace.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko225",
          "To do so, click on the Groups tab at the top of the Book Settings dialog.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a128.png"} height={176} width={408} />
      <p className="lineit">
        {tx("bweniko226", "You will see a field where you can select a group.")}{" "}
      </p>
      <LazyImg img={"/help/en/a130.png"} height={308} width={400} />
      <p className="lineit">
        {tx(
          "bweniko227",
          "To create a New group click on the New button and type in a name for a group.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko228",
          "To remove a group, select the broup and click on the Delete button.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko229",
          "To add users to a group, select the group and just type in email addresses, each one in a new line.",
        )}{" "}
      </p>

      <p className="lineit">
        {tx("bweniko230", "When you are done press the Save button.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko231",
          "To assign the book to all users in this group press the Assign button in the lower right corner.",
        )}{" "}
        {tx(
          "bweniko232",
          "In a few seconds all of these users will have access to your book.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a131.png"} height={163} width={407} />
      <p className="lineit">
        {tx(
          "bweniko233",
          "These users will appear in the Users list under the Users tab now.",
        )}{" "}
      </p>
      <h3 className="subtit" id="kssharelink">
        {tx("bweniko234", "Sharing by Link")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko235",
          "You can alse create a link which others can use to get access to your book.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko236",
          "Click on the Link tab at the top of the Book Settings dialog.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a132.png"} height={334} width={410} />
      <p className="lineit">
        {tx("bweniko237", "You will see now options to create a link.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko240",
          "In the case of a seminar or a webinar for example, it's often much easier to let the attendees sign up themselves for your book than to type in their e-mail addresses one by one.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko238",
          "So if a user clicks on a link created here, they will get a chance to claim this book under their own e-mail address.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko239",
          "When you create a link you can set an expiration time and a maximum number of users who can sign up with this link to avoid the link spreading to people who shouldn't get access to it.",
        )}{" "}
      </p>

      <p className="lineit">
        {tx(
          "bweniko241",
          "So set an expiration date, a maximum number of users, and add a name to the link.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a135.png"} height={228} width={403} />
      <p className="lineit">
        {tx(
          "bweniko242",
          "Then press the Create button and you will see the link listed below.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a140.png"} height={129} width={388} />
      <p className="lineit">
        {tx(
          "bweniko243",
          "To get the link, just click on the Copy link option below the link you want to share and share it with who you want to share it with.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko244",
          "Or in a seminar or in case of personal contact, click on the Show QR code option, and a QR code will appear on your screen.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko245",
          "Your users now can use this to sign up to your book as if they used the link.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko246",
          "You can also make the QR code visible under the Book Settings by clicking on the QR Code button.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a141.png"} height={431} width={406} />
      <p className="lineit">
        {tx(
          "bweniko247",
          "This link is valid until the QR code is shown, but users have to claim the book before you take it off the screen.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a142.png"} height={544} width={409} />
      <h3 className="subtit" id="kssell">
        {tx("bweniko248", "Selling your book")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko249",
          "You can also ask money for your books directly in IniShare.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko250",
          "To do so, just open the book settings menu from the Library, clicking on the little cog in the top right corner of your book.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a120.png"} height={189} width={412} />
      <p className="lineit">
        {tx("bweniko251", "Make sure your book is published.")}{" "}
      </p>
      <LazyImg img={"/help/en/a145.png"} height={67} width={393} />
      <p className="lineit">
        {tx("bweniko252", "Then click on the Sell tab at the top.")}{" "}
      </p>
      <LazyImg img={"/help/en/a144.png"} height={213} width={405} />
      <p className="lineit">
        {tx(
          "bweniko253",
          "Here you can simply type in some sales text you want to show, a price you want to ask and a currency.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a146.png"} height={670} width={398} />
      <p className="lineit">
        {tx(
          "bweniko254",
          "Then check the Sell this book option at the bottom, and press Save.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a150.png"} height={211} width={406} />
      <p className="lineit">
        {tx(
          "bweniko255",
          "You will see a link there which you can send out to people or include in your webpage where users can pay for your book.",
        )}{" "}
      </p>
      <LazyImg img={"/help/en/a151.png"} height={106} width={405} />
      <p className="lineit">
        {tx("bweniko256", "It is simple and is only for sales.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko257",
          "They can buy your book here, and when they finished the payment, the book will get added to their library.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko258",
          "We currently charge 10% of the gross for this service (which we want to decrease as we go).",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko259",
          "You can set yourself up as a seller under the My Account option from the menu where you can add your bank account number where we will transfer money to you and read the rest of the conditions and needed data.",
        )}{" "}
      </p>
      <h3 className="subtit" id="ksclipboard">
        {tx("bweniko167", "The Clipboard")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko168",
          "In IniNotes you have a special Clipboard which makes it easy to research and save random pictures, quotes or sound recordings for later use in your projects.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko169",
          "This Clipboard is a temporary location where you gather these things in various categories for later use in any of your books.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko170",
          "For a first look at the clipboard you can click on the clipboard button which is located in the header wherever you are.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko171",
          "The clipboard has two tabs which you can select from at the top:",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko172",
          "The first tab is where you can add new clippings to the clipboard.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko173",
          "You can type or copy text here, or add a link or drag and drop a file.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko174",
          "Or you can create an audio recording by clicking on the microphone at the bottom:",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko175",
          "Or you can take a photo by clicking at the camera at the bottom:",
        )}{" "}
      </p>
      <p className="lineit">
        {tx("bweniko176", "You can also have all of these together.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko177",
          "Here you can select which clipboard you want to put the data on, and create new clipboards as needed.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko178",
          "When you have the data you want to put on the selected clipboard, just click on the Add button in the lower right corner.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko179",
          "Your clipping got added to the clipboard and you can now create and add new clippings to your clipboards.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko180",
          "At the top you can click on the list button and you will see the clippings you have gathered on this clipboard so far.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko181",
          "You can look at the other clipboards here as well by selecting them here:",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko182",
          "You can also add clippings from your book items to your clipboard.",
        )}{" "}
      </p>
      <p className="lineit">{tx("bweniko183", "Open an item.")} </p>
      <p className="lineit">
        {tx("bweniko184", "Select the lines you want to add to the Clibpoard.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko185",
          "Right click on the selection and press the Template from selection option in the popup menu.",
        )}{" "}
      </p>
      <h3 className="subtit" id="ksaddclip">
        {tx("bweniko186", "Adding from the clipboard")}
      </h3>
      <p className="lineit">
        {tx(
          "bweniko187",
          "Clipboards become really useful when you later want to put together your items.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko188",
          "Open an item and go to the position where you want to add something from the clibpard.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko189",
          "Now click on the clipboard button at the top, and the clipboard opens to the right (on a phone it appears in the middle).",
        )}{" "}
      </p>
      <p className="lineit">
        {tx("bweniko190", "Select the clipboard you want to use as needed.")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko191",
          "Then find your item and click on the insert button next to it.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko192",
          "The clipboard item will be inserted into your text.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx("bweniko193", "You can continue inserting parts.")}{" "}
        {tx(
          "bweniko194",
          "When you are finished with an item on your clipboard, just click on the little x in the top right corner, and it gets deleted from your clipboard.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko195",
          "When you are done with the clipboard you can also close it by clicking on the clipboard button at the top again.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx("bweniko196", "Adding to the clipboard on phones and tablets")}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko197",
          "On phones and tablets you can also use the share function in other apps to add items to the clipboard.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko198",
          "For example if you select some text in your browser, you can click on the Share button:",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko199",
          "And from the list of applications appearing select IniShare.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko200",
          "The Clipboard will open and you will see that the text you selected is now ready to be added to your clipboard.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko201",
          "You can also share websites (then the link goes to the link field), or photos when they are copied to the image field.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko202",
          "Then just press the Add button and it gets added to your clipboard for later use.",
        )}{" "}
      </p>
      <p className="lineit">
        {tx(
          "bweniko203",
          "Of course you can also record sound or add photos as well using the buttons at the bottom.",
        )}{" "}
      </p>
    </React.Fragment>
  );
};

export default HelpText;
