import { combineReducers } from '@reduxjs/toolkit';
import appTransPartsReducer from './reducer.appTransParts';
import authLoadedReducer from './reducer.authLoaded';
import tempLanguageReducer from './reducer.tempLanguage';

const rootReducer = combineReducers({
  appTransParts: appTransPartsReducer,
  authLoaded: authLoadedReducer,
  tempLanguage: tempLanguageReducer,
});

export default rootReducer;
