//nocheck

import { tx } from "@translations/languagedata/globalize";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
// import styled from "styled-components";

const HelpCenterInside = (props) => {
  const { goThere } = props;

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col lg={4} md={6} xs={12} className="mb-4">
            <h5 className="mb-0 text-primary">
              {tx("thizt1", "What is IniShare?")}
            </h5>
            <ul className="list-unstyled mt-2 mb-0">
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kswhatis")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt2", "What is IniShare?")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kswhy")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt3", "Why IniShare?")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kshow")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt4", "How can you share books?")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksuse")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt5", "Use cases")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksinst")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt6", "Getting Started")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksfree")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt7", "Free vs. Pro version")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kshelp")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt8", "Getting Help")}
                </Link>
              </li>
            </ul>
          </Col>

          <Col lg={4} md={6} xs={12} className="mb-4 mb-lg-4">
            <h5 className="mb-0 text-primary">
              {tx("thizt9", "IniShare Books")}
            </h5>
            <ul className="list-unstyled mt-2 mb-0">
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kslibrary")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt10", "The Library")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksbooksett")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt11", "Book Settings")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksview")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt12", "Viewing Books by Others")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksviewyours")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt13", "Viewing Your Books")}
                </Link>
              </li>
            </ul>
          </Col>

          <Col lg={4} md={6} xs={12} className="mb-4 mb-lg-4">
            <h5 className="mb-0 text-primary">
              {tx("thizt14", "Creating Books")}
            </h5>
            <ul className="list-unstyled mt-2 mb-0">
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kscreate")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt15", "Creating a Book")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kscover")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt16", "Designing a Cover")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksaddchapters")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt17", "Adding Chapters")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksaddparts")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt18", "Adding Parts")}
                </Link>
              </li>
              {/* <li className="mt-2">
                    <Link to="#" className="text-muted">
                      <i className="mdi mdi-arrow-right text-primary me-2"></i>
                      API-connected software
                    </Link>
                  </li> */}
            </ul>
          </Col>

          <Col lg={4} md={6} xs={12} className="mb-4 pt-2">
            <h5 className="mb-0 text-primary">{tx("thizt20", "The Editor")}</h5>
            <ul className="list-unstyled mt-2 mb-0">
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kseditor")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt19", "Basics")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksparagraph")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt21", "Paragraph Formatting")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksbold")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt22", "Bold and Italics")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kslists")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt23", "Lists")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kslinks")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt24", "Links")}
                </Link>
              </li>
            </ul>
          </Col>

          <Col lg={4} md={6} xs={12} className="mb-4 pt-2">
            <h5 className="mb-0 text-primary">
              {tx("thizt", "Special Elements")}
            </h5>
            <ul className="list-unstyled mt-2 mb-0">
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksspecial")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt25", "Adding Special Elements")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksvoice")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt26", "Voice Recording")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksphoto")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt27", "Adding Photos")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("ksredo")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt28", "Undo and Redo")}
                </Link>
              </li>
              {/*   <li className="mt-2">
                    <Link to="#" className="text-muted">
                      <i className="mdi mdi-arrow-right text-primary me-2"></i>
                      Tips and Tricks
                    </Link>
                  </li> */}
            </ul>
          </Col>

          <Col lg={4} md={6} xs={12} className="mb-4 pt-2">
            <h5 className="mb-0 text-primary">
              {tx("thizt29", "Sharing Books")}
            </h5>
            <ul className="list-unstyled mt-2 mb-0">
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kspublish")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt30", "Publishing Books")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kssharesingle")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt31", "Sharing with Single Users")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kssharegroup")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt32", "Sharing with Groups")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kssharelink")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt33", "Sharing by Link")}
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="#"
                  className="text-muted"
                  onClick={() => goThere("kssell")}
                >
                  <i className="mdi mdi-arrow-right text-primary me-2"></i>
                  {tx("thizt35", "Selling Books")}
                </Link>
              </li>
            </ul>
          </Col>

          {/* <Col lg={4} md={6} xs={12} className="mt-4 pt-2">
              <h5>Your account</h5>
              <ul className="list-unstyled mt-4 mb-0">
                <li className="mt-2">
                  <Link to="#" className="text-muted">
                    <i className="mdi mdi-arrow-right text-primary me-2"></i>
                    Your profile
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="#" className="text-muted">
                    <i className="mdi mdi-arrow-right text-primary me-2"></i>
                    Account security
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="#" className="text-muted">
                    <i className="mdi mdi-arrow-right text-primary me-2"></i>
                    Identification & verifications
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="#" className="text-muted">
                    <i className="mdi mdi-arrow-right text-primary me-2"></i>
                    Reviews
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="#" className="text-muted">
                    <i className="mdi mdi-arrow-right text-primary me-2"></i>
                    Superhost status
                  </Link>
                </li>
              </ul>
            </Col> */}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default HelpCenterInside;
