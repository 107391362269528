import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { setTempLanguage } from "redux/reducers/reducer.tempLanguage";
import store from "redux/store";
import Translation from "./Translation";

const ifSupported = () => {
  let langer = "en";

  const languages = navigator.languages || [];

  for (let l = 0; l < languages.length; l++) {
    const navLang = languages[l].slice(0, 2);

    if (["en", "hu"].includes(navLang)) {
      langer = navLang;
      break;
    }
  }
  return langer;
};

const TranslationService = (props) => {
  const [ready, setReady] = useState(false);
  const location = useLocation();
  const currentUserLang = useSelector((state) => state.currentUser?.language);

  useEffect(() => {
    const startApp = async () => {
      let language =
        location.search?.match(/lang=[^&]*/)?.[0].replace("lang=", "") ||
        store.getState().currentUser?.language ||
        localStorage.getItem("language") ||
        ifSupported();

      localStorage.setItem("language", language || "");

      if (store.getState().tempLanguage === language && ready) {
        return;
      }

      store.dispatch(setTempLanguage(language));

      // store.dispatch(setAppTransParts({ ...translation, loaded: true }));

      // try {
      //   (await loadAppTranslation(language)) as any;
      // } catch (e) {
      //   console.log(e);
      // }

      setTimeout(() => {
        setReady(true);
      }, 0);
    };

    startApp();
  }, [location.search, ready, currentUserLang]);

  return (
    <React.Fragment>
      <Translation />
    </React.Fragment>
  );
};

export default TranslationService;
