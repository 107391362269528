import { createSlice } from '@reduxjs/toolkit';

const initialState = { init: true };

const appTransPartsSlice = createSlice({
  initialState,
  name: 'appTransParts',
  reducers: {
    setAppTransParts: (state, action) => {
      return action.payload;
    },
  },
});

export const { setAppTransParts } = appTransPartsSlice.actions;

export default appTransPartsSlice.reducer;
