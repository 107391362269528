import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const authLoadedSlice = createSlice({
  initialState,
  name: 'authLoaded',
  reducers: {
    setAuthLoaded: (state, action) => {
      return action.payload;
    },
  },
});

export const { setAuthLoaded } = authLoadedSlice.actions;

export default authLoadedSlice.reducer;
