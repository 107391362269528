import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
// import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('3480669918836260', advancedMatching, options);

// ReactGA.initialize('G-8BNRWGNTBN');
console.log('btest');
const Marketing = (props) => {
  const location = useLocation();

  useEffect(() => {
    // ReactGA.send('pageview');
    ReactPixel.pageView();

  }, [location.pathname]);

  return <div />;
};

export default Marketing;
